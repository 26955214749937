import React, { useEffect } from 'react';
import HK from 'factory/modules/HK';
import { useRouter } from 'next/router';
import { isMobileSSR, isLoggedInSSR, getRequestUrl, httpToHttps, ajaxHelper } from 'factory/utils/serverUtils';
// store import
import { isLogedInAction } from 'factory/store/actions/';
import { requestQueryAction } from 'factory/store/actions/serverActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from 'factory/modules/config';
import PageLayout from 'components/Assets/Layouts/PageLayout';
import { mobSearchOpenAction } from 'components/common/header/action';
import { singleLineHeader } from 'components/common/header/SingleLineHeader/action.js';
import { singleLineFooter } from 'components/common/footer/SingleLineFooter/action';
import dynamic from 'next/dynamic';
import { singleLineHeaderLinkToReducer } from 'components/common/header/SingleLineHeader/reducer';
import { pageTypeAction } from 'components/pageTypeAction';
import { isLoginPopupAction } from 'components/common/login/action';
const HomeComp = dynamic(() => import('components/HomeNew'), { ssr: true });
const Home = (props) => {
  const router = useRouter();
  const { show404 = false,
    pageData,
    isMobile,
    serverIsLoggedin,
    _isLogedInAction,
    pageType = config.pageTypes.trueBasic.tbHome,
    homeSsrData = {},
  } = props;
  const { homeSliderItems = [] } = homeSsrData;
  const requestUrl = router.asPath;
  const openLoginPopup = () => {
    props._isLoginPopupAction({ isShow: true, activeTab: "login", redirectTo: '' });
  };
  useEffect(() => {
    props._singleLineHeader(false);
    props._singleLineFooter(false);
    let isForgotPasswrod = HK.getSearchParmas().forgotpassword;
    if (isForgotPasswrod && isForgotPasswrod == 'true') {
      openLoginPopup();
    } else {
      HK.chatIcon(false);
    }
    HK._updateDataLayer();
  }, []);

  if (serverIsLoggedin) {
    _isLogedInAction(serverIsLoggedin);
  }

  if (router.query) {
    props._requestQueryAction(router.query);
  }

  props._mobSearchOpenAction(true);

  props._pageTypeAction(pageType);


  let preloadImages = null;
  if (homeSliderItems[0]) {
    preloadImages = [homeSliderItems[0].img];
  }
  return (
    <PageLayout
      {...props}
      show404={show404}
      router={router}
      isMobile={isMobile}
      data={{ results: { page: { ...pageData } } }}
      requestUrl={requestUrl}
      pageType={pageType}
      query={router.query}
      preloadImages={preloadImages}
    >
      <HomeComp {...props}  pageType={pageType}/>
    </PageLayout>
  );
};

export async function getServerSideProps({ req}) {
  let isMobile = isMobileSSR(req);
  let serverIsLoggedin = isLoggedInSSR(req);
  let pageData = {
    page: {
      pgSeo: {
        title: "Personalized Nutrition &amp; SuperMilk Protein Drinks for Kids"
      }
    },
    user: []
  };
  try {
    let _url = getRequestUrl(config.APIS.home.firstFold, isMobile, serverIsLoggedin);
    let pageDataJson = await ajaxHelper(_url, req);
    // let pageDataJson = await pageDataRes.json()

    if (!pageDataJson.results.exception) {
      pageData = pageDataJson?.results?.page || null;
      pageData = httpToHttps(pageData);
      let _homeBannerSlider = [];
      pageData.pgBnrSlots && pageData.pgBnrSlots.forEach((bannerSlot) => {
        if (bannerSlot.slot === 'BACKGROUND_SLOT') {
          bannerSlot.images.map((v) => {
            if (!isMobile) {
              return _homeBannerSlider.push({
                link: v.lp,
                title: v.cap,
                img: v.o_link
              });
            }
          });
        } else if (bannerSlot.slot === 'MOBILE720') {
          if (isMobile) {
            bannerSlot.images.map((v) => {
              return _homeBannerSlider.push({
                link: v.lp,
                title: v.cap,
                img: v.o_link
              });
            });
          }
        }
      });

      if (isMobile && (!_homeBannerSlider || _homeBannerSlider.length === 0)) {
        pageData.pgBnrSlots && pageData.pgBnrSlots.forEach((bannerSlot) => {
          if (bannerSlot.slot === 'MOBILE360') {
            bannerSlot.images.map((v) => {
              return _homeBannerSlider.push({
                link: v.lp,
                title: v.cap,
                img: v.o_link
              });
            });
          }
        });
      }
      return {
        props: {
          pageData,
          homeSsrData: {
            pageDataLoaded: true,
            homeSliderItems: _homeBannerSlider,
            pageData: pageData,
            pgSeo: pageData ? pageData.pgSeo : null,
          },
          isMobile,
          serverIsLoggedin,
          show404: false
        }
      };
    } else {
      throw pageDataJson;
    }
  } catch (error) {
    console.log('server error ', error);
    return { props: { pageData: pageData, isMobile, serverIsLoggedin, show404: true } };
  }

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    _isLogedInAction: isLogedInAction,
    _mobSearchOpenAction: mobSearchOpenAction,
    _requestQueryAction: requestQueryAction,
    _singleLineHeaderLinkTo: singleLineHeaderLinkToReducer,
    _singleLineHeader: singleLineHeader,
    _singleLineFooter: singleLineFooter,
    _pageTypeAction: pageTypeAction,
    _isLoginPopupAction: isLoginPopupAction,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Home);